import { storeId, type StoreId } from '@evolutivelabs/rhinoshield-shopify-client'

import { Locale } from '@/graphql/creator-platform/generated'

interface StoreConfig {
  readonly currencyUnit: string
  readonly language: string
  readonly locale: Locale
  readonly requireGdpr: boolean
}

const storeConfigs: Record<StoreId, StoreConfig> = {
  tw: {
    currencyUnit: 'TWD',
    language: 'zh-TW',
    locale: Locale.Tw,
    requireGdpr: false,
  },
  jp: {
    currencyUnit: 'JPY',
    language: 'ja',
    locale: Locale.Jp,
    requireGdpr: false,
  },
  th: {
    currencyUnit: 'THB',
    language: 'th',
    locale: Locale.Th,
    requireGdpr: true,
  },
  asia: {
    currencyUnit: 'USD',
    language: 'en-ASIA',
    locale: Locale.Asia,
    requireGdpr: false,
  },
  io: {
    currencyUnit: 'USD',
    language: 'en',
    locale: Locale.Io,
    requireGdpr: true,
  },
  eu: {
    currencyUnit: 'EUR',
    language: 'en-EU',
    locale: Locale.Eu,
    requireGdpr: true,
  },
  uk: {
    currencyUnit: 'GBP',
    language: 'en-GB',
    locale: Locale.Uk,
    requireGdpr: true,
  },
  fr: {
    currencyUnit: 'EUR',
    language: 'fr',
    locale: Locale.Fr,
    requireGdpr: true,
  },
  de: {
    currencyUnit: 'EUR',
    language: 'de',
    locale: Locale.De,
    requireGdpr: true,
  },
  es: {
    currencyUnit: 'EUR',
    language: 'es',
    locale: Locale.Es,
    requireGdpr: true,
  },
  kr: {
    currencyUnit: 'KRW',
    language: 'ko',
    locale: Locale.Kr,
    requireGdpr: false,
  },
}
export const storeConfig = storeConfigs[storeId]

export enum HostEnv {
  Development = 'development',
  Production = 'production',
  Staging = 'staging',
}

export function getNodeENV(): HostEnv {
  const { host } = window.location
  if (host.includes('my.rhinoshield')) {
    return HostEnv.Production
  } else if (host.includes('my.dev.rhinoshield')) {
    return HostEnv.Staging
  }
  return HostEnv.Development
}

export function getCurrentIsProduction(): boolean {
  return getNodeENV() === HostEnv.Production
}

export function priceWithLocaleCurrencyUnit(price: number): string {
  const priceWithLocaleCurrencyUnit = price.toLocaleString(storeConfig.language, {
    currency: storeConfig.currencyUnit,
    style: 'currency',
    minimumFractionDigits: 0,
  })
  return priceWithLocaleCurrencyUnit
}
