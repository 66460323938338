import { Locale, Location } from '@/graphql/creator-platform/generated'
import { storeConfig } from '@/locale'

// TODO(ayson): 等後端把 BackendLocation 刪掉統一使用 Locale 後再拿掉
export const dittoMappingBackendLocation: Record<Locale, Exclude<Location, Location.All>> = {
  [Locale.Tw]: Location.Tw,
  [Locale.Jp]: Location.Jp,
  [Locale.Th]: Location.Th,
  [Locale.Asia]: Location.Asia,
  [Locale.Io]: Location.Io,
  [Locale.Eu]: Location.Eu,
  [Locale.Uk]: Location.Uk,
  [Locale.Fr]: Location.Fr,
  [Locale.De]: Location.De,
  [Locale.Es]: Location.Es,
  [Locale.Kr]: Location.Kr,
}

export const backendLocation: Location = dittoMappingBackendLocation[storeConfig.locale]

export function isLocalItem(x: { location: Location }): boolean {
  return x.location === backendLocation
}
